import Page from 'classes/Page';

import lottie from 'lottie-web';

export default class Home extends Page {
  constructor() {
    super({
      id: 'homepage',
      element: '.homepage',
      elements: {
        wrapper: '.homepage .page__wrapper',
        navigation: document.querySelector('.navigation'),
        animation: document.getElementById('animation'),
      },
    });
  }

  create() {
    super.create();

    lottie.loadAnimation({
      container: this.elements.animation,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: 'animation.json',
    });
  }
}
